<script lang="ts" setup>
import { Delta, Quill, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import {
getDownloadURL,
ref as storageRef,
uploadBytesResumable,
UploadTask,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { useFirebaseStorage } from "vuefire";
import { ImageUploadBlot } from "~/blots/ImageUploadBlot";
import { SectionHeaderBlot } from "~/blots/SectionHeader";
import { TableOfContentsBlot } from "~/blots/TableOfContents";

const props = defineProps({
  modelValue: {
    type: Object as PropType<any>,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  showFormattingOptions: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minHeight: {
    type: Number,
    default: 200,
    required: false,
  },
  showAdvancedToolbar: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["update:modelValue", "onSave", "onClose"]);

const editor = ref<Quill | undefined>();

const quillToolbarId = ref(`quill-toolbar-${useGenerateUID()}`);
const lastCursorPosition = ref<number>(0);

onMounted(() => {
  // const quillEditor = editor.value.quill;
  // quillEditor.container.addEventListener("keyup", onKeyUp);
  // initializeQuillEvents();
  Quill.register(SectionHeaderBlot);
  Quill.register(TableOfContentsBlot);
  Quill.register(ImageUploadBlot);
});
const content = ref();

const onQuillReady = (ev: any) => {
  content.value = props.modelValue ? new Delta(props.modelValue) : null;

  if (!editor.value) return;

  // @ts-ignore
  const quill = editor.value.getQuill();

  if (!quill) return;

  const toolbar = quill.getModule("toolbar");

  if (!toolbar) return;

  toolbar.addHandler("image", () => {
    imageHandler(quill);
  });

  quill.on("selection-change", (range: any) => {
    if (range) {
      lastCursorPosition.value = range.index;
    }
  });

  quill.root.addEventListener("paste", handlePaste);

  // Listen for text changes to update the TOC
  quill.on("text-change", () => {
    updateTableOfContents(quill);
  });

  // Add click event listener for TOC links
  quill.root.addEventListener("click", (event: any) => {
    const target = event.target as HTMLElement;
    if (
      target &&
      target.tagName === "A" &&
      target.closest(".table-of-contents")
    ) {
      event.preventDefault();

      const editorElement = document.getElementsByClassName("ql-editor")[0];

      // INdex of "A" tag in the table-of-contents .list
      const index = target.getAttribute("data-index");

      if (index === null) return;

      const intIndex = parseInt(index);

      // Get all elements with the class .section-header within editorElement
      const sectionHeaders = editorElement.querySelectorAll(".section-header");

      const sectionHeader = sectionHeaders[intIndex];

      // Convert NodeList to an array and find the element that matches targetContents
      // const sectionHeader = Array.from(sectionHeaders).find((header: any) => {
      //   return header.textContent.includes(targetContents);
      // });

      // Scroll the section header into view
      if (sectionHeader) {
        sectionHeader.scrollIntoView({ block: "start" });
      }
    }
  });
};

const updateTableOfContents = (quill: Quill) => {
  const headers = getSectionHeaders(quill);

  // Generate the TOC HTML

  const tocHTML = headers
    .map((header, index) => {
      return `<div class='flex flex-row items-center py-1'>    
        <a href="javascript:void(0)" data-index="${index}">${index + 1}. ${
        header.text
      }</a>
      </div>`;
    })
    .join("");

  // Update the content of all TOC blots
  const blots = (quill.scroll as any).descendants(TableOfContentsBlot);
  blots.forEach((blot: any) => {
    // Find the ol tag inside of the blot
    const ol = blot.domNode.querySelector(".list");
    if (!ol) return;
    ol.innerHTML = tocHTML;
  });
};

const getSectionHeaders = (quill: Quill) => {
  const headers = [] as any[];
  const lines = quill.getLines();

  lines.forEach((line: any) => {
    const formats = line.formats();

    if (formats && formats["section-header"]) {
      const text = line.domNode.innerText;
      const index = quill.getIndex(line);
      headers.push({ text, index });
    }
  });

  return headers;
};

const handlePaste = async (e: ClipboardEvent) => {
  const quillEditor = editor.value;
  if (!quillEditor) return;

  // Capture the current selection index before preventing default
  const cursorPosition = lastCursorPosition.value;

  // Get the clipboard data
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  let imagePasted = false;

  if (clipboardData && clipboardData.items) {
    const text = clipboardData.getData("text/plain");

    if (text) {
      // If text exists, allow Quill to handle it as usual
      return; // Let QuillJS handle the paste event
    }

    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        // Prevent the default paste action only for images
        e.preventDefault();
        imagePasted = true;

        const file = items[i].getAsFile();
        // Upload the image and insert it at the current cursor position
        await uploadAndInsertImage(file, cursorPosition);
      }
    }
  }

  // If no image was pasted, allow the default behavior to handle text or other content
  if (!imagePasted) {
    console.log("No image pasted");
    // Allow default paste behavior for non-image content
  }
};

const uploadAndInsertImage = (file: File, cursorPosition: number) => {
  const quillEditor = editor.value;
  if (!quillEditor) return;

  // @ts-ignore
  const quill = quillEditor.getQuill();
  if (!quill) return;

  // Generate a unique ID for this placeholder
  const id = uuidv4();

  // Insert placeholder image at the captured cursor position
  const placeholderSrc = URL.createObjectURL(file);
  quill.insertEmbed(cursorPosition, "imageUpload", {
    src: placeholderSrc,
    id: id,
  });

  // Start the upload
  const uploadTask = uploadImage(file);

  // Monitor the upload progress
  uploadTask.on(
    "state_changed",
    (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      updatePlaceholderProgress(id, progress);
    },
    (error: any) => {
      console.error("Error uploading file:", error);
      useBaseToast("Error uploading file", "error");
      removePlaceholderImage(id);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        replacePlaceholderWithImage(id, downloadURL, cursorPosition);
      });
    }
  );
};

const updatePlaceholderProgress = (id: string, progress: number) => {
  const quillEditor = editor.value;
  if (!quillEditor) return;

  // @ts-ignore
  const quill = quillEditor.getQuill();

  const containers = quill.root.querySelectorAll(
    `div.image-uploading-container[data-id="${id}"]`
  );

  containers.forEach((container: any) => {
    const progressBar = container.querySelector(".progress-bar");
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
    }
  });
};

const replacePlaceholderWithImage = (
  id: string,
  url: string,
  cursorPosition: number
) => {
  const quillEditor = editor.value;
  if (!quillEditor) return;

  // @ts-ignore
  const quill = quillEditor.getQuill();

  const containers = quill.root.querySelectorAll(
    `div.image-uploading-container[data-id="${id}"]`
  );

  containers.forEach((container: any) => {
    const blot = Quill.find(container);
    if (blot) {
      const index = quill.getIndex(blot);
      quill.deleteText(index, 1, Quill.sources.SILENT);
      quill.insertEmbed(index, "image", url, Quill.sources.USER);
    }
  });

  quill.setSelection(cursorPosition + 1, 0, Quill.sources.SILENT);
};

const removePlaceholderImage = (id: string) => {
  const quillEditor = editor.value;
  if (!quillEditor) return;

  // @ts-ignore
  const quill = quillEditor.getQuill();

  const containers = quill.root.querySelectorAll(
    `div.image-uploading-container[data-id="${id}"]`
  );

  containers.forEach((container: any) => {
    const blot = Quill.find(container);
    if (blot) {
      blot.deleteAt(0, 1);
    }
  });
};

const uploadImage = (file: File): UploadTask => {
  const imageName = uuidv4(); // Generate a unique name for the image
  const storage = useFirebaseStorage();
  const imageRef = storageRef(storage, `/public-resources/${imageName}`);

  const uploadTask = uploadBytesResumable(imageRef, file);
  return uploadTask;
};

const imageHandler = (editor: Quill) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.click();

  input.onchange = async () => {
    const file = input.files?.[0];
    if (!file) return;

    try {
      // Get the current selection index
      const selection = editor.getSelection();
      const cursorPosition = selection ? selection.index : 0;

      await uploadAndInsertImage(file, cursorPosition);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
};

// const insertToEditor = (url: string, editor: Quill) => {
//   const range = editor.getSelection(true);
//   editor.insertEmbed(range.index, "image", url);
// };

const toolbar =
  props.readOnly || props.disabled ? ref([]) : ref(`#${quillToolbarId.value}`);

const onKeyDown = (event: KeyboardEvent) => {
  // if (event.shiftKey && event.key === '"') {
  //   event.preventDefault();
  //   formatQuotes();
  // } else if (event.key == "Backspace" || event.key == "Delete") {
  //   checkQuotes(event);
  // } else if (event.key === "Æ" && event.altKey) {
  //   event.preventDefault();
  //   formatQuotes("”");
  // }

  if (event.key == " ") {
    checkEmoji();
  }
};

const checkEmoji = () => {
  const quillEditor = editor.value;

  if (!quillEditor) {
    return;
  }
  // @ts-ignore
  const quill = quillEditor.getQuill();

  const selection = quill.getSelection();
  if (!selection) return;
  const [line, offset] = quill.getLine(selection.index);

  const textBefore = line.domNode.textContent.substring(offset - 2, offset);
  const matchingEmoji = emojiShortcuts.value[textBefore];

  if (matchingEmoji) {
    // Delete the range of text
    quill.deleteText(selection.index - 2, 2);

    // Insert new text (emoji in this case) at the start index
    quill.insertText(selection.index - 2, matchingEmoji);
  }
};

const onCheckQuotes = () => {
  const quillEditor = editor.value;

  if (!quillEditor) {
    return;
  }

  // @ts-ignore
  const quill = quillEditor.getQuill();
  const text = quill.getText();
  const length = text.length;

  let cursor = 0;
  while (cursor < length) {
    // Find the index of the next quote (straight, open, or close)
    const quoteIndex = text.substring(cursor).search(/["“”]/);
    if (quoteIndex === -1) break; // No more quotes found

    const absoluteIndex = cursor + quoteIndex;

    // Determine if the quote should be open or close
    const isOpenQuote =
      absoluteIndex === 0 || /\s/.test(text[absoluteIndex - 1]);
    const currentQuote = text[absoluteIndex];

    let replacementQuote;
    if (currentQuote === '"') {
      replacementQuote = isOpenQuote ? "“" : "”";
    } else if (isOpenQuote && currentQuote === "”") {
      replacementQuote = "“";
    } else if (!isOpenQuote && currentQuote === "“") {
      replacementQuote = "”";
    }

    if (replacementQuote) {
      // Create the delta for the replacement
      const delta = new Delta()
        .retain(absoluteIndex) // Retain all text before the quote
        .delete(1) // Delete the current quote
        .insert(replacementQuote); // Insert the correct quote

      // Apply the delta
      quill.updateContents(delta);
    }

    // Move cursor forward
    cursor = absoluteIndex + 1;
  }
};

const emojis = ref(["😀", "😊", "👍", "🎉", "🎯", "💯"]);

const emojiShortcuts = ref<{ [key: string]: string }>({
  ":)": "😀",

  ":3": "😊",

  ":>": "😆",
  ":^)": "😆",
});

const insertText = (text: string, includeLineBreak: boolean = true) => {
  const quillEditor = editor.value;

  if (!quillEditor) {
    return;
  }

  // @ts-ignore
  const quill = quillEditor.getQuill();
  const range = quill.getSelection(true);

  if (!range) {
    return;
  }

  quill.insertText(range.index, text, Quill.sources.USER);

  if (includeLineBreak) {
    quill.insertText(range.index + text.length, "\n", Quill.sources.USER);
  }

  quill.setSelection(range.index + text.length, Quill.sources.SILENT);
};

const editorContainer = ref();

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "header",
  "size",
  "color",
  "background",
  "link",
  "image",
  "section-header",
  "table-of-contents",
  "imageUpload",
  "list", // For ordered and bullet lists
  "indent", // For indentation
];

// Handler for the SectionHeader button
const sectionHeaderHandler = () => {
  // @ts-ignore
  const quill = editor.value.getQuill();
  const range = quill.getSelection();
  if (range) {
    quill.formatLine(range.index, range.length, "section-header", true);
  }
};

// Handler for the TableOfContents button
const tocHandler = () => {
  // @ts-ignore
  const quill = editor.value.getQuill();
  const range = quill.getSelection(true);
  quill.insertEmbed(range.index, "table-of-contents", true, Quill.sources.USER);
};
</script>

<template>
  <div
    class="flex flex-col"
    :class="{
      disabled: disabled,
      'read-only': readOnly,
    }"
  >
    <div
      :id="quillToolbarId"
      class="bg-surface border- border-medium-tint rounded mb-2 flex flex-row items-center justify-between w-full"
      :class="{
        '!mb-0': readOnly,
      }"
    >
      <div
        v-if="readOnly != true && disabled != true"
        class="flex flex-row items-center"
      >
        <!-- Heading dropdown -->
        <select
          v-if="showFormattingOptions && showAdvancedToolbar"
          class="ql-header"
        >
          <option selected />
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option value="4" />
          <option value="5" />
          <option value="6" />
        </select>
        <div class="mx-2">|</div>
        <button
          v-tooltip="'Section'"
          class="section-header-button"
          @click="sectionHeaderHandler"
        >
          <Icon name="mdi:format-header-pound" />
        </button>
        <button
          v-tooltip="'Table of Contents'"
          class="table-of-contents-button"
          @click="tocHandler"
        >
          <Icon name="oui:table-of-contents" />
        </button>
        <div class="mx-2">|</div>
        <button v-if="showFormattingOptions" class="ql-bold" />
        <button v-if="showFormattingOptions" class="ql-italic" />
        <button v-if="showFormattingOptions" class="ql-underline" />
        <button v-if="showFormattingOptions" class="ql-strike" />
        <div class="mx-2">|</div>
        <button v-if="showFormattingOptions" class="ql-list" value="ordered" />
        <button v-if="showFormattingOptions" class="ql-list" value="bullet" />
        <button v-if="showFormattingOptions" class="ql-indent" value="-1" />
        <!-- Indent less -->
        <button v-if="showFormattingOptions" class="ql-indent" value="+1" />
        <!-- Indent more -->
        <div class="mx-2">|</div>
        <button
          v-if="showFormattingOptions && showAdvancedToolbar"
          class="ql-link"
        />
        <button
          v-if="showFormattingOptions && showAdvancedToolbar"
          class="ql-image"
        />

        <div class="mx-2">|</div>
        <!-- Text color picker -->
        <select
          v-if="showFormattingOptions && showAdvancedToolbar"
          class="ql-color"
        />
        <select
          v-if="showFormattingOptions && showAdvancedToolbar"
          class="ql-background"
        />
        <div class="mx-2">|</div>

        <VMenu placement="top">
          <BaseIconButton> 😊 </BaseIconButton>
          <template #popper>
            <div class="bg-surface grid grid-cols-6 p-2 justify-start">
              <BaseIconButton
                v-for="(emoji, index) in emojis"
                :key="index"
                @click="insertText(emoji, false)"
              >
                {{ emoji }}
              </BaseIconButton>
            </div>
          </template>
        </VMenu>
      </div>
    </div>
    <div
      ref="editorContainer"
      class="editor-container border border-medium-tint rounded grow overflow-auto"
      :style="{}"
    >
      <QuillEditor
        ref="editor"
        :toolbar="toolbar"
        theme="snow"
        :options="{
          bounds: editorContainer,
          formats: formats,
        }"
        :content="content"
        :read-only="readOnly || disabled"
        :enable="!readOnly && !disabled"
        :disabled="disabled"
        @update:content="$emit('update:modelValue', $event.ops)"
        @ready="onQuillReady"
        @keydown="onKeyDown"
        @keyup="onCheckQuotes"
      />
    </div>
  </div>
</template>

<style>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: unset;
}

.ql-container.ql-snow {
  border: unset;
}

.ql-container,
.ql-editor {
  @apply bg-surface;
}

.disabled .ql-toolbar,
.read-only .ql-toolbar {
  display: none;
}

.disabled #quill-toolbar,
.read-only #quill-toolbar {
  margin-bottom: 0px;
}

.read-only .ql-container,
.read-only .ql-editor,
.read-only .editor-container {
  @apply bg-transparent;
  @apply border-none;
  @apply p-0 !important;
  border: none !important;
}

.disabled .ql-container,
.disabled .ql-editor {
  @apply bg-light-tint;
  @apply !text-[#030202];
}

.ql-editor {
  border: unset;
}

.section-header {
  border-bottom: 1px solid black;
  margin-bottom: 8px !important;
  scroll-margin-top: 16px;
}

.table-of-contents a {
  cursor: pointer;
  @apply text-primary-default;
  @apply !no-underline;
}

.image-uploading-container {
  position: relative;
  display: inline-block;
}

.image-uploading-container .progress-bar {
  height: 8px;
  @apply bg-success-default;
  width: 0%;
  @apply mb-2;
  @apply rounded;
}

.image-uploading-container img {
  max-width: 100%;
}
</style>
